import React from "react";
import { Helmet } from "react-helmet";

const CategoryThree = () => {

  const additionalContent = `
  Gomis sisters mary immaculate. We help children to forget their bitter memories about their parents and different kinds of life experiences by loving them and by listening to them. Also, the Sisters coordinate with them to look forward through the love of Christ by changing their bad attitudes towards society. Our aim is to train the children to face the world confidently as respectable citizens.
  
  Our Approach:
  Gomis sisters mary immaculate.
  - Shelter: Providing a safe and secure environment.
  - Love: Offering unconditional care and support.
  - Nutrition: Ensuring access to nutritious meals.
  - Healthcare: Prioritizing their physical well-being.
  - Education: Providing quality learning opportunities.
  - Counseling: Offering guidance and emotional support.
  - Life skills: Equipping them with essential skills for independent living.
  
  Gomis sisters mary immaculate. As a Christian organization following the Catholic tradition, the Sisters of Mary Immaculate draw inspiration from the Gospel of Jesus Christ. We hold a deep appreciation for the spiritual dimension of our lives. We respect and honor the children's individual traditions, including their religion and language. Our commitment is to facilitate their aspirations and foster an environment where their unique traditions are cherished and respected by the Sisters.
`;

  return (
    <>

<Helmet>
        <title>Gomis Sisters of Mary Immaculate</title>
        <meta
          name="description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          name="keywords"
          content="Gomis Sisters, Mary Immaculate, Home for Seniors, Faith, Compassion, Miracles, Jesus"
        />
        <meta property="og:title" content="Gomis Sisters of Mary Immaculate" />
        <meta
          property="og:description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          property="og:image"
          content="https://gsmi.lk/assets/img/bg/Stone1.jpg"
        />
      </Helmet>
      {/* <!-- cta area start --> */}
      <div className="tp-cta-area pt-140">
        <div className="container">
          <div className="tp-cta-wrapper">
            <div className="row">
              <div className="col-12">
                <div className="tp-section-wrapper text-center">
                  
                  <h2 className="fs-4">
                  “In the contemporary world, the eldest and children are the most abandoned and 
marginalized. The children gain the attention of the others, while the elderly are treated 
as a wound to society. We have a duty to treat them well and help them in their time of 
need. First of all, we should help them find their own strength in their mind and spirit. 
When they are no longer able to do so, we should be their shoulders and offer them our 
kindness. Let us be their feet and arm s and make old age a beautiful experience full of 
peace and love"
                  </h2>
                  <span className="tp-section-subtitle fs-6 mb-25 pt-15">
                  His Grace the Most Reverend Dr Oswald Gomis
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- cta end end --> */}
    </>
  );
};

export default CategoryThree;

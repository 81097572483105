import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import BlogDetailsBanner from "./BlogDetailsBanner";
import BlogDetailsPost from "./BlogDetailsPost";
import { Helmet } from "react-helmet";

const BlogDetails = () => {
  return (
    <>
      <Helmet>
        <title>Miracles of Hope: Embracing Love, Loss, and Redemption</title>
        <meta
          name="description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta name="keywords" content="Miracles of Hope, Love, Loss, Redemption, Tsunami Disaster, Grace of Jesus" />
        <meta property="og:title" content="Miracles of Hope: Embracing Love, Loss, and Redemption" />
        <meta
          property="og:description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta property="og:image" content="https://gsmi.lk/assets/img/bg/Stone1.jpg" />
      </Helmet>
      <Menu header_style_2={true} />
      <BlogDetailsBanner />
      <BlogDetailsPost />
      <FooterThree />
    </>
  );
};

export default BlogDetails;

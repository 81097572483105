import React from "react";
import { Link } from "react-router-dom";
import Contact from "../../common/Contact/Contact";
import { Helmet } from "react-helmet";

const BlogDetailsPost = () => {
  return (
    <>
     <Helmet>
        <title>Miracles of Hope: Embracing Love, Loss, and Redemption</title>
        <meta
          name="description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta name="keywords" content="Miracles of Hope, Love, Loss, Redemption, Tsunami Disaster, Grace of Jesus" />
        <meta property="og:title" content="Miracles of Hope: Embracing Love, Loss, and Redemption" />
        <meta
          property="og:description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta property="og:image" content="https://gsmi.lk/assets/img/bg/Stone1.jpg" />
      </Helmet>

      
      <div className="postbox__area pt-80 pb-120">
        <div className="container">
          <div className="row">
            <div className="">
              <div className="postbox__wrapper ">
              <h3 className="postbox__title mb-25 text-center">
              Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus
                    </h3>
                <article className="postbox__item format-image mb-50 transition-3">
                  <div className="postbox__thumb m-img text-center">
                    <Link to="/blog-details">
                      <img src="assets/img/OurStory/mainbanner.jpg" alt="" />
                    </Link>
                  </div>
                  <div className="postbox__content">
                    <div className="postbox__meta mb-25">
                      
                    </div>
                    
                    <div className="postbox__text text-center">
                      <p>
                        In the wake of the devastating tsunami that struck in
                        2004, our orphanage found itself with heartache and
                        loss. Young souls, innocent and fragile, were suddenly
                        left without the guiding presence of their parents. But
                        amid the wreckage and the tears, a new chapter began.
                      </p>
                      <p>
                        Blessed with the arrival of these beautiful children,
                        our orphanage became a home of love, compassion, and
                        growth. We embarked on a journey, not just to heal
                        wounds but to nurture young hearts into becoming
                        skilled, empowered individuals. Cruising through life
                        with travelling around the country on trips, not one
                        birthday goes uncelebrated and everyday feels like
                        Christmas here at the orphanage.
                      </p>
                      <p>
                        These children, once shattered by tragedy, embraced each
                        opportunity with open arms. They learned to sew, stitch
                        by stitch weaving dreams into reality. Education became
                        their ladder to reach for the stars, each book a step
                        towards a brighter future. With stanch dedication, they
                        soaked in knowledge and wisdom, transforming into
                        inspirations for those who followed in their footsteps.
                      </p>
                      <p>
                        Today, those once-fragile children have blossomed into
                        remarkable adults, standing tall as a testament to the
                        power of resilience and love. Their skilled hands not
                        only craft beautiful garments but also mend broken
                        spirits. Their minds, enriched by education, now guide
                        the younger generation towards a path of self-discovery
                        and achievement. As employed adults, they have chosen a
                        path of service, paying forward the compassion they
                        received during their own vulnerable times.
                      </p>
                      <p>Looking around, one can't help but be overwhelmed by the
                      beauty of this journey. The orphanage that once sheltered
                      grief has been transformed into a thriving community of
                      survivors, united by shared experiences and strengthened
                      by collective triumphs. The tsunami may have taken away so
                      much, but it also paved the way for a remarkable story of
                      growth, and the strong spirit of the human heart.</p>
                      
                    
                    </div>
                    
                  </div>
                </article>
                
              </div>
            </div>
            <Contact/>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogDetailsPost;

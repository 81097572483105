import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const ContactUsBanner = () => {
  return (
    <>
     <Helmet>
        <title>Contact Us</title>
        <meta property="og:image" content="https://gsmi.lk/assets/img/bg/Stone1.jpg" />
        <meta property="og:description" content="Contact the Gomis Sisters Mary Immaculate for information on their mission, services, and locations. Reach out to us for spiritual guidance and community support." />
      </Helmet>
      <section
        className="breadcrumb__area include-bg breadcrumb__overlay contact_us_banner"
        // data-background="assets/img/breadcrumb/breadcrumb-bg-2.jpg"
        style={{
          backgroundImage: `url("assets/img/bg/banner001.jpg")`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">Contact Us</h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="/">Home</Link>
                  </span>
                  <span className="dvdr">:</span>
                  <span>contact</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUsBanner;

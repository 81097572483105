import React from "react";
import { Link } from "react-router-dom";

const FooterThree = () => {
  return (
    <footer>
      <div className="tp-footer__area black-bg">
        <div className="tp-footer">
          {/* <!-- main-footer start  --> */}
          <div className="tp-footer__main">
            <div className="container">
              <div className="tp-footer-border pt-100 pb-0">
                <div className="row">
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Child Care Centers
                      </h3>

                      <ul>
                        <li>
                          <a href="#">Kelaniya</a>
                        </li>
                        <li>
                          <a href="#">Katupotha - Kurunagala</a>
                        </li>
                      
                        <h3 className=" tp-footer__widget tp-footer__2 pt-50 tp-footer__widget-title text-white">
                        Contact Us
                      </h3>
                      <li><a>Sri Lanka Address:</a></li>
                      <li><a>Rev. Mother Superior Sr Amatha Sniviratna,</a></li>
                      <li><a>Sisters of Mary Immaculate,</a></li>
                      <li><a>Basilicawatta Tewatta Ragama.</a></li>
                        <li>
                         <a>Contact
                          Number: +94770806552 </a><br/><a>Rev.Sr Raphael : +94762616252</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2 pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Elder's Care Centers
                      </h3>
                      <ul>
                        <li>
                          <a href="#">Kelaniya (main branch)</a>
                        </li>
                        <li>
                          <a href="#">Bopitiya (main branch)</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 ">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <h3 className="tp-footer__widget-title text-white">
                        Quick Links
                      </h3>
                      <ul>
                        <li>
                          <a href="#">Home</a>
                        </li>
                        <li>
                          <a href="#">Gallery</a>
                        </li>
                        <li>
                          <a href="#">Our Story</a>
                        </li>
                        <li>
                          <a href="#">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="tp-footer__widget tp-footer__2  pb-30">
                      <ul>
                      <h3 className="tp-footer__widget-title text-white">
                        Our Global Locations
                      </h3>
                        <li>
                          <a href="#">
                            Suore di Maria Immacolata Basilica Santuario Madonna
                            del Carmine{" "}
                          </a>
                        </li>
                        <li>
                          <a>Maggiore</a>
                        </li>
                        <li>
                          <a>Piazza Carmine, Napoli</a>
                        </li>
                        <br />
                        <li>
                          <a href="#">
                            Bimba Mondo, Via San Pietro in Lucone 7,Polpenazze
                            del Garda
                          </a>
                        </li>
                        <li>
                          <a>BS</a>
                        </li>
                        <br />
                        <li>
                          <a href="#">
                            Centro Spiritualità della Misericordia, Chiesa San
                            Agostano, Piazza Sant’Agostino
                          </a>
                        </li>
                        <li>
                          <a>Gela</a>
                        </li>
                        <br />
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- main footer end  --> */}
        </div>
      </div>

      {/* <!-- footer copy right --> */}
      <div className="top-footer-copyright pt-30 pb-30 black-bg">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="tp-copyrigh-text text-center text-white">
                <span>
                  © {new Date().getFullYear()} Gomis Sisters of Mary Immaculate
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- footer copyright end  --> */}
    </footer>
  );
};

export default FooterThree;

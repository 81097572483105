import React from "react";
import Brands from "../common/Brands/Brands";
import Footer from "../common/Footer/Footer";
import FooterThree from "../common/Footer/FooterThree";
import FullScreenBtn from "../common/FullScreenBtn";
import Menu from "../common/Menu/Menu";
import Team from "../common/Team/Team";
import Testimonial from "../common/Testimonial/Testimonial";
import AboutThree from "./AboutThree";
import BlogThree from "./BlogThree";
import CategoryThree from "./CategoryThree";
import ContactThree from "./ContactThree";
import PortfolioThree from "./PortfolioThree";
import PortfolioThree2 from "./PortfilioThree2";
import Promotion1 from "./Promotion1"
import Promotion from "./Promotion";
import SliderHomeThree from "./SliderHomeThree";
import { Helmet } from "react-helmet";

const HomeThree = () => {

  const additionalContent = `
  Gomis sisters mary immaculate. We help children to forget their bitter memories about their parents and different kinds of life experiences by loving them and by listening to them. Also, the Sisters coordinate with them to look forward through the love of Christ by changing their bad attitudes towards society. Our aim is to train the children to face the world confidently as respectable citizens.
  
  Our Approach:
  Gomis sisters mary immaculate.
  - Shelter: Providing a safe and secure environment.
  - Love: Offering unconditional care and support.
  - Nutrition: Ensuring access to nutritious meals.
  - Healthcare: Prioritizing their physical well-being.
  - Education: Providing quality learning opportunities.
  - Counseling: Offering guidance and emotional support.
  - Life skills: Equipping them with essential skills for independent living.
  
  Gomis sisters mary immaculate. As a Christian organization following the Catholic tradition, the Sisters of Mary Immaculate draw inspiration from the Gospel of Jesus Christ. We hold a deep appreciation for the spiritual dimension of our lives. We respect and honor the children's individual traditions, including their religion and language. Our commitment is to facilitate their aspirations and foster an environment where their unique traditions are cherished and respected by the Sisters.
`;

  return (
    <>

<Helmet>
        <title>Gomis Sisters of Mary Immaculate</title>
        <meta
          name="description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          name="keywords"
          content="Gomis Sisters, Mary Immaculate, Home for Seniors, Faith, Compassion, Miracles, Jesus"
        />
        <meta property="og:title" content="Gomis Sisters of Mary Immaculate" />
        <meta
          property="og:description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          property="og:image"
          content="https://gsmi.lk/assets/img/bg/Stone1.jpg"
        />
      </Helmet>

      <Menu header_style_2={true} />
      <SliderHomeThree />
      <AboutThree />
      <CategoryThree />
      <FullScreenBtn />
      <PortfolioThree />
      <Promotion1 />
      <PortfolioThree2 />
      <Promotion />
      <Team />
      <Testimonial />
      <Brands />
      <ContactThree />
      {/* <BlogThree /> */}
      <FooterThree />
    </>
  );
};

export default HomeThree;

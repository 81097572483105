import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { Helmet } from 'react-helmet';

const ContactThree = () => {
  const additionalContent = `
  Gomis sisters mary immaculate. We help children to forget their bitter memories about their parents and different kinds of life experiences by loving them and by listening to them. Also, the Sisters coordinate with them to look forward through the love of Christ by changing their bad attitudes towards society. Our aim is to train the children to face the world confidently as respectable citizens.
  
  Our Approach:
  Gomis sisters mary immaculate.
  - Shelter: Providing a safe and secure environment.
  - Love: Offering unconditional care and support.
  - Nutrition: Ensuring access to nutritious meals.
  - Healthcare: Prioritizing their physical well-being.
  - Education: Providing quality learning opportunities.
  - Counseling: Offering guidance and emotional support.
  - Life skills: Equipping them with essential skills for independent living.
  
  Gomis sisters mary immaculate. As a Christian organization following the Catholic tradition, the Sisters of Mary Immaculate draw inspiration from the Gospel of Jesus Christ. We hold a deep appreciation for the spiritual dimension of our lives. We respect and honor the children's individual traditions, including their religion and language. Our commitment is to facilitate their aspirations and foster an environment where their unique traditions are cherished and respected by the Sisters.
`;


  const form = useRef();
  const [success,setSucsess] = useState(null);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_98p0feq', 'template_46noxb2', form.current, 'FbyIl09n0UwtXsBD-')
      .then((result) => {
          console.log(result.text);
          setSucsess(true)
      }, (error) => {
          console.log(error.text);
          setSucsess(false)
      });
  };
 

  return (
    <>
    <Helmet>
        <title>Gomis Sisters of Mary Immaculate</title>
        <meta
          name="description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          name="keywords"
          content="Gomis Sisters, Mary Immaculate, Home for Seniors, Faith, Compassion, Miracles, Jesus"
        />
        <meta property="og:title" content="Gomis Sisters of Mary Immaculate" />
        <meta
          property="og:description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          property="og:image"
          content="https://gsmi.lk/assets/img/bg/Stone1.jpg"
        />
      </Helmet>
      {/* <!-- contact area start --> */}
      <div
        className="tp-contact-area"
        // data-background="assets/img/contact/contact.jpg"
        style={{ backgroundImage: `url("assets/img/bg/sister2.jpg")` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-contact-info pt-180 pb-150 pr-50">
                <div className="tp-section-wrapper p-color-change">
                  <span className="tp-section-subtitle text-white mb-15">
                    Get in touch now
                  </span>
                  <h2 className="tp-section-title text-white mb-15">
                    Support our mission to spread the love of Jesus Christ
                  </h2>
                  <p className="">
                    Join us in making a positive impact by sharing His
                    teachings, extending compassion, and inspiring lives.
                    Together, we can make a difference. Donate now!
                  </p>
                  <div className="mb-1">
                    {" "}
                    <p style={{ height: "10px", fontWeight: "600" }}>
                      Account Name : Sisters of Mary Immaculate
                    </p>
                    <p style={{ height: "10px" }}>
                      Account Number: 138010016695
                    </p>
                    <p> Bank Name : HNB Kelaniya Branch</p>
                  </div>
                  <p style={{ height: "10px", fontWeight: "600" }}>
                    Account Name : St.Anne Elders Home
                  </p>
                  <p style={{ height: "10px" }}>Account Number: 138010013342</p>
                  <p> Bank Name : HNB, Kelaniya</p>
                  <div className="mt-50"></div>
                  <p style={{ height: "10px", fontWeight: "600" }}>
                    Rev. Mother Superior Sr Amatha Sniviratna
                  </p>
                  <p style={{ height: "10px" }}>Contact Number: +94770806552</p>
                  <p> Rev.Sr Raphael  : +94762616252</p>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="tp-contact-form white-bg pt-60 pl-50 pr-50 pb-60">
                <h4 className="tp-contact-form-title">
                  <i className="fal fa-file-edit"></i>Online Contact pasindu
                </h4>


                <form ref={form} onSubmit={sendEmail}>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter your name"
                  />
                  <br />
                  <input
                    type="email"
                    name="email"
                    placeholder="Enter your mail"
                  />
                  <br />
                  <textarea
                    name="messege"
                    placeholder="Enter your Message"
                  ></textarea>
                  <button type="submit" value="Send" className="tp-btn-border">
                    Send Message
                    <span>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                      <svg
                        width="22"
                        height="8"
                        viewBox="0 0 22 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M21.3536 4.35356C21.5488 4.15829 21.5488 3.84171 21.3536 3.64645L18.1716 0.464468C17.9763 0.269205 17.6597 0.269205 17.4645 0.464468C17.2692 0.65973 17.2692 0.976312 17.4645 1.17157L20.2929 4L17.4645 6.82843C17.2692 7.02369 17.2692 7.34027 17.4645 7.53554C17.6597 7.7308 17.9763 7.7308 18.1716 7.53554L21.3536 4.35356ZM-4.37114e-08 4.5L21 4.5L21 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
                          fill="currentColor"
                        ></path>
                      </svg>
                    </span>
                  </button>
                  {success && "your meesage sent we'll get you soon"}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- contact area end  -->  */}
    </>
  );
};

export default ContactThree;

import React from "react";
import { Helmet } from "react-helmet";

const Promotion1 = () => {

  const additionalContent = `
  Gomis sisters mary immaculate. We help children to forget their bitter memories about their parents and different kinds of life experiences by loving them and by listening to them. Also, the Sisters coordinate with them to look forward through the love of Christ by changing their bad attitudes towards society. Our aim is to train the children to face the world confidently as respectable citizens.
  
  Our Approach:
  Gomis sisters mary immaculate.
  - Shelter: Providing a safe and secure environment.
  - Love: Offering unconditional care and support.
  - Nutrition: Ensuring access to nutritious meals.
  - Healthcare: Prioritizing their physical well-being.
  - Education: Providing quality learning opportunities.
  - Counseling: Offering guidance and emotional support.
  - Life skills: Equipping them with essential skills for independent living.
  
  Gomis sisters mary immaculate. As a Christian organization following the Catholic tradition, the Sisters of Mary Immaculate draw inspiration from the Gospel of Jesus Christ. We hold a deep appreciation for the spiritual dimension of our lives. We respect and honor the children's individual traditions, including their religion and language. Our commitment is to facilitate their aspirations and foster an environment where their unique traditions are cherished and respected by the Sisters.
`;

  return (
    <>
    <Helmet>
        <title>Gomis Sisters of Mary Immaculate</title>
        <meta
          name="description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          name="keywords"
          content="Gomis Sisters, Mary Immaculate, Home for Seniors, Faith, Compassion, Miracles, Jesus"
        />
        <meta property="og:title" content="Gomis Sisters of Mary Immaculate" />
        <meta
          property="og:description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          property="og:image"
          content="https://gsmi.lk/assets/img/bg/Stone1.jpg"
        />
      </Helmet>
      {/* <!-- promotion area start  --> */}
      <div className="tp-promotion-area p-relative grey-bg-4">
        <div
          className="tp-promotion-bg grey-bg"
          style={{
            backgroundImage: `url('assets/img/bg/elders4.png')`,
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="tp-promotion-info pt-110 pb-80 pl-120">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40">
                    Our Mission and Vision for Elders Home
                  </h2>
                </div>
                <p>
                  At St. Anne's Elders Home, we are driven by our mission to
                  provide a dignified and compassionate living environment for
                  homeless elders. Guided by our vision, we strive to offer
                  utmost commitment, dedication, and care to those entrusted to
                  us.
                  <br />
                  <br />
                  <p className="fs-5 font-weight-bolder">Mission :</p>
                  "For whoever gives you a cup of water to drink in My name,
                  because you belong to Christ, assuredly, I say to you, he will
                  by no means lose his reward" (Mark 9:41).
                  <br />
                  <br />
                  <p className="fs-5 font-weight-bolder">Vission :</p>
                  We envision a home where the most needed elders find respect,
                  compassion, and love, while we dedicate ourselves to improving
                  the quality of their lives. Our goal is to create a
                  comfortable and dignified living environment, ensuring the
                  physical, emotional, mental, and spiritual well-being of each
                  resident.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tp-promotion-area p-relative grey-bg-4">
        <div
          className="tp-promotion-bg-2"
          style={{
            backgroundImage: `url('/assets/img/bg/elders5.jpg')`,
            backgroundPosition: "center",
            backgroundSize: "cover", // Keeps entire image, fitting inside the element
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-promotion-info-2 pt-110 pb-120 pr-60 pl-120">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40 ">Our Commitments</h2>
                </div>
                <p>
                  At St. Anne's Elders Home, our mission is to provide attentive
                  and loving care in a peaceful and pleasant environment. We
                  prioritize the well-being of our residents by offering timely
                  medical support and ensuring access to nutritious meals and a
                  safe living environment. With utmost respect, we treat every
                  resident with dignity and provide comprehensive and
                  individualized care to all who seek our services. Our
                  dedicated team addresses both the physical and cognitive needs
                  of our residents, surpassing their expectations. We also offer
                  dedicated counseling services, attentively listening to their
                  concerns and providing emotional support. <br />
                  <br />
                  By fostering an intergenerational family environment, we
                  engage in activities with the children from our care centers,
                  creating a sense of connection and companionship.
                  Additionally, we provide valuable opportunities for career
                  expansion in the medical care field through practicum sessions
                  at our 'Midi-Aid' center. As a testament to our founder's
                  contributions, we proudly carry the name 'Gomis Sisters of
                  Mary Immaculate' in honor of His Grace Bishop Gomis and his
                  extensive service to the church. Through our unwavering
                  commitment to these principles, we strive to create a
                  comfortable and dignified life for the elders entrusted to our
                  care, ensuring their well-being and happiness.
                </p>
                <div className="tp-promotion-progress">
                  <div className="tp-promotion-graph"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- promotion area end  --> */}
    </>
  );
};

export default Promotion1;

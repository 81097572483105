const menu_data = [
  {
    id: 1,
    hasDropdown: true,
    title: 'Home',
    link: '/',
    submenus: [
     
    ]
  },
  {
    id: 2,
    title: 'GALLERY',
    link: '/portfolio',
  },
  {
    id: 3,
    hasDropdown: true,
    title: 'Our Story',
    link: '/blog-details',
    submenus: [
   
    ]
  },
  {
    id: 4,
    hasDropdown: true,
    title: 'Contact Us',
    link: '/contact',
    submenus: [
 
    ]
  },

]

export default menu_data;

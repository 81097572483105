import { useState } from "react";
import ImagePopup from "../../common/Modals/ImagePopup";


// portfolio_data
const portfolio_data = [
  {
    index: 0,
    img: "/assets/img/Elders/20230531_204306.webp",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 1,
    img: "/assets/img/Elders/20230703_092954.webp",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 2,
    img: "/assets/img/Elders/20211114_134329.webp",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 3,
    img: "/assets/img/Elders/20211114_133824.webp",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 4,
    img: "/assets/img/Elders/20211114_133732.webp",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 5,
    img: "/assets/img/Elders/20220424_193153.jpg",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 6,
    img: "/assets/img/Elders/20220424_194232.jpg",
    tag: "Business",
    category: "Elders",
  },
  {
    index: 7,
    img: "/assets/img/children/child1.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
    
  },
  {
    index: 8,
    img: "/assets/img/children/20220117_103437.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 9,
    img: "/assets/img/children/20220117_135651.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 10,
    img: "/assets/img/children/20220227_190515.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 11,
    img: "/assets/img/children/20220723_125826.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 12,
    img: "/assets/img/children/20230622_125028.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 13,
    img: "/assets/img/children/20230901_125841.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 14,
    img: "/assets/img/children/20220326_180725.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 15,
    img: "/assets/img/children/20230901_125841.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 16,
    img: "/assets/img/children/20230901_131855.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 17,
    img: "/assets/img/children/20230622_125028.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 18,
    img: "/assets/img/children/20230622_155306.jpg",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },
  {
    index: 19,
    img: "/assets/img/children/20230901_132203.webp",
    tag: "Data Analysis",
    category: "Children",
    col: "6",
  },

  {
    index: 20,
    img: "/assets/img/Activities/20220114_172002.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20220227_181653.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20220227_182558.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20220327_171413-picsay.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20220327_171648-picsay.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20230514_155639.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20230514_080811.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 20,
    img: "/assets/img/Activities/20221212_161500.webp",
    tag: "Business",
    category: "Activities",
    col: "6",
    mb: "30",
  },
  {
    index: 21,
    img: "/assets/img/environment/20230626_072646.webp",
    tag: "Product Design",
    category: "Environment",
    Title : "Hello",
    col: "10",
  },
  {
    index: 22,
    img: "/assets/img/environment/En9.webp",
    tag: "Product Design",
    category: "Environment",
    col: "10",
  },
  {
    index: 23,
    img: "/assets/img/environment/En10.webp",
    tag: "Product Design",
    category: "Environment",
    col: "10",
  },
  {
    index: 24,
    img: "/assets/img/environment/20220324_172935.webp",
    tag: "Product Design",
    category: "Environment",
    col: "10",
  },
  {
    index: 25,
    img: "/assets/img/environment/20220319_182032.webp",
    tag: "Product Design",
    category: "Environment",
    col: "10",
  },


];

const centerOne = () => {
  return (
    <p>
    
    </p>
  );
};
/* The code snippet is defining a React functional component called `PortfolioThree2`. */

// categories
const categories = [
  "All",
  ...new Set(portfolio_data.map((item) => item.category)),
];
/**
 * The function `PortfolioThree2` is a React component that manages the active state and portfolio
 * items for a portfolio display.
 */

const GallerySection = () => {
  const [isActive, setIsActive] = useState("All");
  const [portfolioItems, setPortfolioItems] = useState(portfolio_data);

  // photoIndex
  const [photoIndex, setPhotoIndex] = useState(null);
  // image open state
  const [isOpen, setIsOpen] = useState(false);
  // handleImagePopup
  const handleImagePopup = (i) => {
    setPhotoIndex(i);
    setIsOpen(true);
  };
  //  images
  const images = [...new Set(portfolio_data.map((item) => item.img))];

  // handle portfolio data
  const handlePortfolioData = (category) => {
    setIsActive(category);
    if (category === "All") {
      setPortfolioItems(portfolio_data);
    } else {
      setPortfolioItems(
        portfolio_data.filter((item) => item.category === category)
      );
    }
  };
  return (
    <>
      {/* <!-- portfolio area start  --> */}
      <div className="tp-portfolio-area">
        <div className="container p-0">
          <div className="tp-portfolio-header mb-70">
            <div className="row align-items-center g-0 ">
              <div className="col-lg-5 col-12">
                <div className="tp-portfolio-info">

                  <h3 className="tp-portfolio-title">Our Image Gallery</h3>
                </div>
              </div>
              <div className="col-lg-0 col-12">
                <div className="tp-pf-btn text-lg-end mt-35">
                  {categories.map((cate, i) => (
                    <button
                      key={i}
                      onClick={() => handlePortfolioData(cate)}
                      className={`${isActive === cate ? "active" : ""}`}
                    >
                      {cate.split("-").join(" ").toUpperCase()}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            {centerOne()}
          </div>

          <div className="row tp-portfolio-active centerOne">
            {portfolioItems
              .filter((item) => item.category === "Elders")
              .map((item) => (
                <div key={item.index} className="col-lg-4 tp-portfolio-item">
                  <div className="fix">
                    <button
                      onClick={() => handleImagePopup(item.index)}
                      className="project-gallery-image"
                    >
                      <div className={`image-container ${isActive === "All" ? "fixed-size" : ""}`}>
            <img src={item.img} alt="" />
          </div>
                    </button>
                  </div>
                  <div className="portfolio-tag">
                    
                  </div>
                </div>
              ))}

            <div className="col-lg-8">
              <div className="row">
                {portfolioItems
                  .filter((item) => item.category !== "Elders")
                  .map((item, i) => (
                    <div
                      key={i}
                      className={`col-lg-${item.col} tp-portfolio-item w-img mb-${item.mb}`}
                    >
                      <div className="fix">
                        <button
                          onClick={() => handleImagePopup(item.index)}
                          href="#"
                          className="project-gallery-image"
                        >
                          <img src={item.img} alt="" />
                        </button>
                      </div>
                      
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="text-center mb-reset mt-70 mb-140">
            
          </div>
        </div>
      </div>
      {/* <!-- portfolio area end  --> */}

      {/* image light box start */}
      {isOpen && (
        <ImagePopup
          images={images}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
      {/* image light box end */}
    </>
  );
};

export default GallerySection;

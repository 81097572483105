import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

const BlogDetailsBanner = () => {
  return (
    <>
      <Helmet>
        <title>Miracles of Hope: Embracing Love, Loss, and Redemption</title>
        <meta
          name="description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta name="keywords" content="Miracles of Hope, Love, Loss, Redemption, Tsunami Disaster, Grace of Jesus" />
        <meta property="og:title" content="Miracles of Hope: Embracing Love, Loss, and Redemption" />
        <meta
          property="og:description"
          content="Miracles of Hope: Embracing Love, Loss, and Redemption in the Wake of the 2004 Tsunami Disaster through the Grace of Jesus. Learn about our journey of healing and transformation in the aftermath of the devastating tsunami."
        />
        <meta property="og:image" content="https://gsmi.lk/assets/img/bg/Stone1.jpg" />
      </Helmet>
      <section
        className="breadcrumb__area include-bg pt-150 pb-150 breadcrumb__overlay blog_details_banner"
        // data-background="assets/img/breadcrumb/breadcrumb-bg-2.jpg"
        style={{
          backgroundImage: `url("assets/img/OurStory/mothermary.png")`,
        }}
      >
        <div className="container pt-200 pb-200">
          <div className="row">
            <div className="col-xxl-12">
              <div className="breadcrumb__content text-center p-relative z-index-1">
                <h3 className="breadcrumb__title">Our Humble Beginning</h3>
                <div className="breadcrumb__list">
                  <span>
                    <Link to="">Our Story</Link>
                  </span>
                  <span className="dvdr">:</span>
                  <span>life at GSMI</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogDetailsBanner;

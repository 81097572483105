import React from "react";
import { Helmet } from "react-helmet";


const Promotion = () => {

  const additionalContent = `
  Gomis sisters mary immaculate. We help children to forget their bitter memories about their parents and different kinds of life experiences by loving them and by listening to them. Also, the Sisters coordinate with them to look forward through the love of Christ by changing their bad attitudes towards society. Our aim is to train the children to face the world confidently as respectable citizens.
  
  Our Approach:
  Gomis sisters mary immaculate.
  - Shelter: Providing a safe and secure environment.
  - Love: Offering unconditional care and support.
  - Nutrition: Ensuring access to nutritious meals.
  - Healthcare: Prioritizing their physical well-being.
  - Education: Providing quality learning opportunities.
  - Counseling: Offering guidance and emotional support.
  - Life skills: Equipping them with essential skills for independent living.
  
  Gomis sisters mary immaculate. As a Christian organization following the Catholic tradition, the Sisters of Mary Immaculate draw inspiration from the Gospel of Jesus Christ. We hold a deep appreciation for the spiritual dimension of our lives. We respect and honor the children's individual traditions, including their religion and language. Our commitment is to facilitate their aspirations and foster an environment where their unique traditions are cherished and respected by the Sisters.
`;

  return (
    <>

<Helmet>
        <title>Gomis Sisters of Mary Immaculate</title>
        <meta
          name="description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          name="keywords"
          content="Gomis Sisters, Mary Immaculate, Home for Seniors, Faith, Compassion, Miracles, Jesus"
        />
        <meta property="og:title" content="Gomis Sisters of Mary Immaculate" />
        <meta
          property="og:description"
          content={`Embracing Jesus' Path of Compassion For a Spiritual Journey. A Faith-Filled Home for Seniors Where Miracles Happen Encountering God's Love Within These Walls. FAITHFULLY SERVING WITH JESUS. ${additionalContent}`}
        />
        <meta
          property="og:image"
          content="https://gsmi.lk/assets/img/bg/Stone1.jpg"
        />
      </Helmet>
      {/* <!-- promotion area start  --> */}
      <div className="tp-promotion-area p-relative grey-bg-4">
        <div
          className="tp-promotion-bg grey-bg"
          style={{
            backgroundImage: `url('assets/img/bg/sister2.png')`,
            backgroundPosition: 'center',
            backgroundSize : "cover"
          }}
        ></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="tp-promotion-info pt-110 pb-80 pl-120">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40">
                    Our mission and vision for Child Care Centers.
                  </h2>
                </div>
                <div className="tp-promotion-progress">
                  <p>
                    "Let the Children Come to me"
                    <br />
                    <br />
                    <p className="fs-5 font-weight-bolder">The vision :</p>
                    Build up the children who rejected from the society by
                    giving them parents love.
                    <br />
                    <br />
                    <p className="fs-5 font-weight-bolder">Mission Statement:</p> 

                    This project is committed to the spiritual, mental, physical
                    and emotional wholeness of children needing residents care.
                    We supposed to build up their abilities changing the
                    environment of the children.
                    <br /> <br />
                    <p className="fs-5 font-weight-bolder">Aims and objectives of the project:</p>
              
                    We help Children to forget their bitter memories about their
                    parents and different kind of life experience by love them
                    and by listening to them. Also the Sisters us to co-ordinate
                    them to look forward through the love of Christ by changing
                    their bad attitudes that they have bout the society. The aim
                    is to train the children to face to the world confidently as
                    respectable citizens.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="tp-promotion-area p-relative grey-bg-4">
        <div
          className="tp-promotion-bg-2"
          style={{
            backgroundImage: `url('/assets/img/bg/sister3.png')`,
            backgroundPosition : "Center",
            backgroundSize : "cover"
          }}
        ></div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6">
              <div className="tp-promotion-info-2 pt-110 pb-120 pl-120">
                <div className="tp-section-wrapper">
                  <h2 className="tp-pt-size mb-40">
                  Our Approach
                  </h2>
                </div>
                <div className="tp-promotion-progress">
                  <div className="tp-promotion-graph pr-70 fs-6">
                    <p className="promotionHeadings fs-5"></p>
                    At the core of our approach is the understanding of
                    each child's unique mindset. Rather than seeking to change
                    them, we aim to guide them towards the right path and
                    empower them to achieve their own personal success.
                    <br /> <br /><p className="fs-5 font-weight-bolder">In our childcare
                    project, we prioritize the following categories:</p> 
                    Shelter: Providing a safe and secure environment.
                    <br/> Love: Offering unconditional care and support. <br />
                    Nutrition: Ensuring access to nutritious meals. <br />
                    Healthcare: Prioritizing their physical well-being.
                    <br/> Education: Providing quality learning opportunities.
                    <br/> Counseling: Offering guidance and emotional support.
                    <br/> Life skills: Equipping them with essential skills for
                    independent living. <br /> <br /> As a Christian
                    organization following the Catholic tradition, the Sisters
                    of Mary Immaculate draw inspiration from the Gospel of Jesus
                    Christ. We hold a deep appreciation for the spiritual
                    dimension of our lives. We respect and honor the children's
                    individual traditions, including their religion and
                    language. Our commitment is to facilitate their aspirations
                    and foster an environment where their unique traditions are
                    cherished and respected by the Sisters.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- promotion area end  --> */}
    </>
  );
};

export default Promotion;

import React from "react";
import FooterThree from "../../common/Footer/FooterThree";
import Menu from "../../common/Menu/Menu";
import ContactInfo from "./ContactInfo";
import ContactUsBanner from "./ContactUsBanner";
import { Helmet } from "react-helmet";

const ContactUs = () => {
  return (
    <>
     <Helmet>
        <title>Contact Us</title>
        <meta property="og:image" content="https://gsmi.lk/assets/img/bg/Stone1.jpg" />
        <meta property="og:description" content="Contact the Gomis Sisters Mary Immaculate for information on their mission, services, and locations. Reach out to us for spiritual guidance and community support." />
      </Helmet>
      <Menu header_style_2={true} />
      <ContactUsBanner />
      <ContactInfo />
      <FooterThree />
    </>
  );
};

export default ContactUs;
